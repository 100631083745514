import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

class CancelDialog extends React.Component {
  render() {
    const {
      title,
      content,
      open,
      agreeButton,
      disagreeButton,
      backTo,
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={disagreeButton}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={disagreeButton} color="primary">
            No
          </Button>
          {agreeButton && (
            <Button onClick={agreeButton} color="primary">
              Yes
            </Button>
          )}
          {backTo && (
            <Link
              to={backTo}
              className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary"
            >
              Yes
            </Link>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default CancelDialog;
