import React from "react";
// import DATA from "./Data";
// import MaterialTable from "material-table";
import { withSnackbar } from "notistack";

class UsersTablePage extends React.Component {
  render() {
    return (
      <div>Users</div>
    );
  }
}
export default withSnackbar(UsersTablePage);
