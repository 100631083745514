import React from "react";
import { Route, Redirect } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { AuthConsumer } from "../context/AuthContext";
import NavigationMenu from "../components/navigationMenu/NavigationMenu";
import Header from "../components/header/Header";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <AuthConsumer>
      {({ isAuth }) => (
        <Route
          render={(props) =>
            isAuth ? (
              <React.Fragment>
                <CssBaseline />
                <Header />
                <Grid container id="main-grid">
                  <Grid item xs={2} id="menu-container">
                    <NavigationMenu color="primary" />
                  </Grid>
                  <Grid item xs={10} id="main-container">
                    <Component {...props} />
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <Redirect to="/" />
            )
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
}
export default PrivateRoute;
