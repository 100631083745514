import React from "react";
import { auth, setAuthToken } from "../../Actions";
import { withSnackbar } from "notistack";
import sha1 from "js-sha1";

const STORAGE_USER_KEY = "storage_user_key";

export const AuthContext = React.createContext();

class AuthenticationProvider extends React.Component {
  state = {
    isAuth: false,
    user: {},
  };

  constructor() {
    super();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    let storedUser = localStorage.getItem(STORAGE_USER_KEY);
    if (!!storedUser) {
      storedUser = JSON.parse(storedUser);
      setAuthToken(storedUser.authToken);
    }
    this.setState({
      isAuth: !!storedUser,
      user: storedUser,
    });
  }

  login(username, password) {
    const sha1Password = sha1(password);
    const loginPromise = auth(username, sha1Password);
    loginPromise
      .then((response) => {
        let authToken = window.btoa(username + ":" + sha1Password);
        let user = response.data || {};
        user = {
          ...user,
          authToken: authToken,
        };
        setAuthToken(authToken);
        this.setState({
          isAuth: true,
          user,
        });
        this.props.enqueueSnackbar(`Welcome ${user.username}!`, {
          variant: "success",
        });
        localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user));
      })
      .catch((error) => {
        console.log(error);
        this.props.enqueueSnackbar(`Sorry, provided credentials are invalid!`, {
          variant: "error",
        });
      });
    return loginPromise;
  }

  logout() {
    this.setState({
      isAuth: false,
      user: {},
    });
    this.props.enqueueSnackbar(`You have been logged out.`, {
      variant: "success",
    });
    localStorage.removeItem(STORAGE_USER_KEY);
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          user: this.state.user,
          login: this.login,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
const AuthProvider = withSnackbar(AuthenticationProvider);
const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
