import React from "react";
import {
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Paper,
  withStyles,
  Button,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import {
  getPlaceByID,
  getAllCountries,
  editPlace,
  addPlace,
  getLanguages,
} from "../../../../Actions";
import { withSnackbar } from "notistack";
import { placeCategory } from "../../../misc/constObjects";
import CancelDialog from "../../dialogs/CancelDialog";
import PlaceLanguagesForm from "./PlaceLanguagesForm";
import PhotosTable from "./photos/PhotosTable";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
});

class PlaceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      place: {
        languages: [],
      },
      countriesList: [],
      allAvailableLanguages: [],
      openDialog: false,
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  async componentDidMount() {
    try {
      const countriesResponse = await getAllCountries();
      const languagesResponse = await getLanguages();
      this.setState({
        countriesList: countriesResponse.data,
        allAvailableLanguages: languagesResponse.data,
      });

      if (this.props.type === "Edit") {
        const placeResponse = await getPlaceByID(this.props.placeID);
        this.setState({
          place: placeResponse.data,
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar(`Error while fetching data. ` + error, {
        variant: "error",
      });
    }
  }

  handleFieldChange(event) {
    this.setState({
      place: {
        ...this.state.place,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleDateChange(e) {
    this.setState((prevState) => ({
      place: {
        ...prevState.place,
        publish_date: format(e, "yyyy-MM-dd"),
      },
    }));
  }

  handleTranslationValue = (value, index) => {
    const langId = this.state.allAvailableLanguages[index].id;
    const translations = this.state.place.languages;
    // updating translation or creating new entry
    const updatedTranslation = translations.find(
      (trans) => trans.id_language === langId
    ) ?? {
      id_language: langId,
    };
    const updatedValue = {
      ...updatedTranslation,
      ...value,
    };
    // update translation in languages list
    var updatedIndex = translations.findIndex(
      (trans) => trans.id_language === langId
    );
    updatedIndex = updatedIndex >= 0 ? updatedIndex : translations.length;
    translations[updatedIndex] = updatedValue;

    this.setState((prevState) => ({
      place: {
        ...prevState.place,
        languages: translations,
      },
    }));
  };

  handleDialogOpen() {
    this.setState({ openDialog: true });
  }
  handleDialogClose() {
    this.setState({ openDialog: false });
  }

  handleSave() {
    if (this.props.type === "Edit") {
      editPlace(this.props.placeID, this.state.place)
        .then((result) => {
          this.props.enqueueSnackbar(`Place updated`, {
            variant: "success",
          });
        })
        .catch((err) => {
          this.props.enqueueSnackbar(`Error while saving data.`, {
            variant: "error",
          });
        });
    }
    if (this.props.type === "Add") {
      addPlace(this.state.place)
        .then((result) => {
          this.props.enqueueSnackbar(`New data was added. ` + result, {
            variant: "success",
          });
          this.props.history.push(`/places/edit/${result.data.id}`);
        })
        .catch((err) => {
          this.props.enqueueSnackbar(`Error while adding data. ` + err, {
            variant: "error",
          });
        });
    }
  }

  render() {
    const { classes, placeID, type } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h3">{this.props.type} Place</Typography>
        <Paper className="padding-20" id="edit-form">
          <Typography variant="h5" className="padding-bottom-20">
            Main informations
          </Typography>
          <form className={classes.root}>
            <FormControl fullWidth>
              <TextField
                id="display_name"
                name="display_name"
                label="Place name"
                value={this.state.place.display_name ?? ""}
                variant="outlined"
                onChange={this.handleFieldChange}
                className="display_name"
                required
              />
              <TextField
                id="id_country"
                name="id_country"
                label="Country"
                variant="outlined"
                select
                required
                value={this.state.place.id_country ?? ""}
                onChange={this.handleFieldChange}
                className="id_country"
              >
                {this.state.countriesList.map((option) => (
                  <MenuItem key={option.id} value={option.id} name={option.id}>
                    {option.display_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="category"
                name="category"
                label="Category"
                variant="outlined"
                select
                required
                value={this.state.place.category ?? ""}
                onChange={this.handleFieldChange}
                className="category"
              >
                {placeCategory.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl>
              <TextField
                id="latitude"
                name="latitude"
                label="Latitude"
                value={this.state.place.latitude ?? ""}
                variant="outlined"
                type="number"
                required
                onChange={this.handleFieldChange}
                className="latitude"
              />
            </FormControl>
            <FormControl>
              <TextField
                id="longitude"
                name="longitude"
                label="Longitude"
                value={this.state.place.longitude ?? ""}
                variant="outlined"
                type="number"
                required
                onChange={this.handleFieldChange}
                className="longitude"
              />
            </FormControl>
            <FormControl style={{ width: "50%" }}>
              <TextField
                id="website"
                name="website"
                label="Website"
                value={this.state.place.website ?? ""}
                variant="outlined"
                type="text"
                onChange={this.handleFieldChange}
                className="website"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">http://</InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <div>
              <FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="publish_date"
                    name="publish_date"
                    label="Publish date"
                    value={this.state.place.publish_date ?? ""}
                    onChange={this.handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl>
                <TextField
                  id="created_at"
                  name="created_at"
                  label="Created at"
                  value={this.state.place.created_at ?? ""}
                  variant="outlined"
                  type="text"
                  disabled
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="modified_at"
                  name="modified_at"
                  label="Modified at"
                  value={this.state.place.modified_at ?? ""}
                  variant="outlined"
                  type="text"
                  disabled
                />
              </FormControl>
            </div>
            <hr />
            <PlaceLanguagesForm
              availableLanguages={this.state.allAvailableLanguages}
              translations={this.state.place.languages}
              handleTranslationValue={this.handleTranslationValue}
            />

            <FormControl fullWidth>
              <Grid container item xs={12} spacing={2} justify="center">
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={this.handleDialogOpen}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={this.handleSave}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </Paper>

        {type === "Edit" && (
          <Paper className="padding-20 margin-top-20">
            <Typography variant="h5" className="padding-bottom-20">
              Photos
            </Typography>
            <PhotosTable placeId={Number(placeID)} />
            <Grid
              container
              justify="center"
              item
              xs={12}
              spacing={2}
              className="padding-top-20"
            >
              <Grid item xs={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    this.props.history.push(`/places/edit/${placeID}/photo`);
                  }}
                >
                  Add Photo
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}

        <CancelDialog
          title="Cancel work?"
          content="Do you really want to cancel? Your're work will be removed."
          open={this.state.openDialog}
          disagreeButton={this.handleDialogClose}
          backTo="/places"
        />
      </React.Fragment>
    );
  }
}

export default withRouter(withSnackbar(withStyles(styles)(PlaceForm)));
