import React from "react";
import { withSnackbar } from "notistack";

class SettingsPage extends React.Component {
  render() {
    return (
      <div>Settings</div>
    );
  }
}
export default withSnackbar(SettingsPage);
