import React from "react";
import List from "@material-ui/core/List";
import Place from "@material-ui/icons/Place";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import PeopleIcon from "@material-ui/icons/People";
import Language from "@material-ui/icons/Language";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import NavigationLink from "./NavigationLink";

class NavigationMenu extends React.Component {
  render() {
    return (
      <React.Fragment>
        <List
          component="menu"
          className="main-menu"
          aria-labelledby="nested-list-subheader"
        >
          <NavigationLink to="/places" name="Places" icon={<Place />} />
          <NavigationLink
            to="/countries"
            name="Countries"
            icon={<EmojiFlagsIcon />}
          />
          <NavigationLink
            to="/languages"
            name="Languages"
            icon={<Language />}
          />
          <NavigationLink to="/users" name="Users" icon={<PeopleIcon />} />
          <NavigationLink
            to="/feedback"
            name="Feedback"
            icon={<FeedbackOutlinedIcon />}
          />
          <NavigationLink
            to="/settings"
            name="Settings"
            icon={<SettingsOutlinedIcon />}
          />
        </List>
      </React.Fragment>
    );
  }
}
export default NavigationMenu;
