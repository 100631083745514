export const placeCategory = [
  { id: "BEACHES", name: "Beaches" },
  { id: "BRIDGES", name: "Bridges" },
  { id: "ISLANDS", name: "Islands" },
  { id: "MUSEUMS", name: "Museums" },
  { id: "MONUMENTS_AND_STATUES", name: "Monuments & Statues" },
  { id: "MOUNTAINS_AND_VOLCANO", name: "Mountains & Volcano" },
  { id: "PARKS_AND_NATURE", name: "Parks & Nature" },
  { id: "SIGHTS_AND_LANDMARKS", name: "Sights & Landmarks" },
  { id: "SACRED_AND_RELIGIOUS", name: "Sacred & Religious" },
];

export const translateStatus = [
  { id: "NEW", name: "New translation" },
  { id: "IN_PROGRESS", name: "Translation in progress" },
  { id: "DONE", name: "Translation done" },
];

export const continents = [
  { id: "AFRICA", name: "Africa" },
  { id: "ASIA", name: "Asia" },
  { id: "AUSTRALIA", name: "Australia" },
  { id: "EUROPE", name: "Europe" },
  { id: "NORTH_AMERICA", name: "North America" },
  { id: "SOUTH_AMERICA", name: "South America" },
];