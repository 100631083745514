import React from "react";
import {
  ListItemIcon,
  Divider,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default function NavigationLink(props) {
  return (
    <React.Fragment>
      <Link to={props.to}>
        <ListItem button>
          <ListItemIcon>{props.icon}</ListItemIcon>
          <ListItemText primary={props.name} />
        </ListItem>
      </Link>
      <Divider />
    </React.Fragment>
  );
}
