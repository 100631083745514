import React from "react";
import MaterialTable from "material-table";
import { withSnackbar } from "notistack";
import { getCountries } from "../../../Actions";

class CountriesTablePage extends React.Component {

  displayName = { title: "Name", field: "display_name", defaultSort: "asc" };

  constructor(props) {
    super(props);

    this.state = {
      pageSize: 30
    };
    
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  handleChangeRowsPerPage(pageSize) {
    this.setState({ pageSize: pageSize });
  }

  loadData(query) {
    if (typeof query.orderBy === "undefined") {
      query.orderBy = this.displayName;
      query.orderDirection = this.displayName.defaultSort;
    }
    return getCountries(
      query.page * query.pageSize,
      query.pageSize,
      query.orderBy.field,
      query.orderDirection.toUpperCase(),
      query.search
    )
      .then((result) => {
        return {
          data: result.data.entities,
          page: query.page,
          totalCount: result.data.total,
        };
      })
      .catch((err) =>
        this.props.enqueueSnackbar(`Error while loading data.`, {
          variant: "error",
        })
      );
  }

  render() {
    return (
      <MaterialTable
        title="Countries"
        columns={[
          //{ title: "Flag", field: "flag_url", render: rowData => <img src={rowData.flag_url} style={{width: 40}}/>},
          this.displayName,
          {
            title: "Continent",
            field: "continent",
            lookup: {
              AFRICA: "Africa",
              ASIA: "Asia",
              AUSTRALIA: "Australia",
              EUROPE: "Europe",
              NORTH_AMERICA: "North America",
              SOUTH_AMERICA: "South America",
            },
          },
          {
            title: "Area size",
            field: "area",
            type: "numeric",
            filtering: false,
          },
          {
            title: "Population",
            field: "population",
            type: "numeric",
            filtering: false,
          },
        ]}
        data={(query) => this.loadData(query)}
        options={{
          filtering: false,
          search: true,
          actionsColumnIndex: -1,
          pageSize: this.state.pageSize,
          pageSizeOptions: [30, 40, 50],
        }}
        onChangeRowsPerPage={(pageSize) => {
          this.handleChangeRowsPerPage(pageSize);
        }}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                console.log("Old data: ", oldData);
                this.props.enqueueSnackbar(`The data has been deleted.`, {
                  variant: "success",
                });
                resolve();
              }, 600);
            }),
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Add",
            isFreeAction: true,
            onClick: (event) => {
              this.props.history.push("/countries/add");
            },
          },
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              this.props.history.push("/countries/edit/" + rowData.id);
            },
          },
        ]}
      />
    );
  }
}
export default withSnackbar(CountriesTablePage);
