import React from "react";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
  TextField,
  MenuItem,
  withStyles,
  Tabs,
  Tab,
  AppBar,
  Typography,
} from "@material-ui/core";
import { LanguageTabPanel } from "./LanguageTabPanel";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMCE_API_KEY, TINYMCE_INIT_SETTINGS } from "../../../../settings";
import { translateStatus } from "../../../misc/constObjects";

const styles = (theme) => ({
  description: {
    marginBottom: theme.spacing.unit,
  },
});

class PlaceLanguagesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange = (_, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  matchLanguagesAndTranslations = (languages, translations) =>
    languages.map((language) => {
      const translation = translations.find(
        (item) => item.id_language === language.id
      );
      return {
        ...language,
        translation: translation ?? {},
      };
    });

  render() {
    const { tabIndex } = this.state;
    const { availableLanguages, translations } = this.props;

    const languagesWithTranslations = this.matchLanguagesAndTranslations(
      availableLanguages,
      translations
    );

    return (
      <div className="TranslationTabs">
        <Typography variant="h5" className="padding-top-20 padding-bottom-20">
          Translations
        </Typography>
        <AppBar position="static">
          <Tabs
            value={tabIndex}
            onChange={this.handleTabChange}
            aria-label="translation-tabs"
          >
            {languagesWithTranslations.map((item, index) => (
              <Tab
                key={index}
                id={"simple-tab-" + index}
                aria-controls={"translation-tabpanel-" + item.id}
                label={item.name}
                icon={
                  item.translation.translation_status === "DONE" ? (
                    <CheckCircleIcon />
                  ) : item.translation.translation_status === "NEW" ? (
                    <NewReleasesIcon />
                  ) : (
                    <AutorenewIcon />
                  )
                }
              />
            ))}
          </Tabs>
        </AppBar>
        {languagesWithTranslations.map((item, index) => (
          <LanguageTabPanel value={tabIndex} index={index} key={index}>
            <TextField
              id={"name-" + item.id}
              name="name"
              label={"Name in: " + item.name}
              variant="outlined"
              fullWidth
              value={item.translation.name ? item.translation.name : ""}
              onChange={(e) =>
                this.props.handleTranslationValue(
                  { name: e.target.value },
                  index
                )
              }
            />
            <Editor
              id={"description-" + item.id}
              name="description"
              label={"Description in: " + item.name}
              apiKey={TINYMCE_API_KEY}
              init={TINYMCE_INIT_SETTINGS}
              value={
                item.translation.description ? item.translation.description : ""
              }
              onEditorChange={(text) =>
                this.props.handleTranslationValue({ description: text }, index)
              }
            />
            <TextField
              id={"region-" + item.id}
              name={"region"}
              label={"Region in: " + item.name}
              variant="outlined"
              fullWidth
              value={item.translation.region ? item.translation.region : ""}
              onChange={(e) =>
                this.props.handleTranslationValue(
                  { region: e.target.value },
                  index
                )
              }
            />
            <TextField
              id={"copyright-" + item.id}
              name={"copyright"}
              label={"Copyright in: " + item.name}
              variant="outlined"
              fullWidth
              value={
                item.translation.copyright ? item.translation.copyright : ""
              }
              onChange={(e) =>
                this.props.handleTranslationValue(
                  { copyright: e.target.value },
                  index
                )
              }
            />
            <TextField
              id={"translation_status-" + item.id}
              name={"translation_status"}
              label={"Status in: " + item.name}
              variant="outlined"
              fullWidth
              select
              value={item.translation.translation_status ?? ""}
              onChange={(e) =>
                this.props.handleTranslationValue(
                  { translation_status: e.target.value },
                  index
                )
              }
            >
              {translateStatus.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </LanguageTabPanel>
        ))}
      </div>
    );
  }
}
export default withStyles(styles)(PlaceLanguagesForm);
