import React from "react";
import {
  Typography,
  Paper,
  withStyles,
  FormControl,
  TextField,
  Button,
  MenuItem,
  Grid,
} from "@material-ui/core";
import {
  addCountry,
  editCountry,
  getCountryById,
  getLanguages,
} from "../../../../Actions";
import { withSnackbar } from "notistack";
import CancelDialog from "../../dialogs/CancelDialog";
import { continents } from "../../../misc/constObjects";
import CountryLanguagesForm from "./CountryLanguagesForm";

const styles = (theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
});

class CountryForm extends React.Component {
  state = {
    country: {
      languages: [],
    },
    allAvailableLanguages: [],
    openDialog: false,
  };

  constructor(props) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleTranslationValue = this.handleTranslationValue.bind(this);
  }

  async componentDidMount() {
    try {
      const languagesResponse = await getLanguages();
      this.setState({
        allAvailableLanguages: languagesResponse.data,
      });

      if (this.props.countryID) {
        const countryResponse = await getCountryById(this.props.countryID);
        this.setState({
          country: countryResponse.data,
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar(`Error while fetching data. ` + error, {
        variant: "error",
      });
    }
  }

  handleFieldChange(event) {
    this.setState({
      country: {
        ...this.state.country,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleTranslationValue = (value, index) => {
    const langId = this.state.allAvailableLanguages[index].id;
    const translations = this.state.country.languages;
    // updating translation or creating new entry
    const updatedTranslation = translations.find(
      (trans) => trans.id_language === langId
    ) ?? {
      id_language: langId,
    };
    const updatedValue = {
      ...updatedTranslation,
      ...value,
    };
    // update translation in languages list
    var updatedIndex = translations.findIndex(
      (trans) => trans.id_language === langId
    );
    updatedIndex = updatedIndex >= 0 ? updatedIndex : translations.length;
    translations[updatedIndex] = updatedValue;

    this.setState((prevState) => ({
      country: {
        ...prevState.country,
        languages: translations,
      },
    }));
  };

  handleSave() {
    if (this.props.type === "Edit") {
      editCountry(this.props.countryID, this.state.country)
        .then(() => {
          this.props.enqueueSnackbar(`Country updated`, {
            variant: "success",
          });
        })
        .catch((err) => {
          this.props.enqueueSnackbar(`Error while saving data. ` + err, {
            variant: "error",
          });
        });
    }
    if (this.props.type === "Add") {
      addCountry(this.state.country)
        .then((result) => {
          this.props.enqueueSnackbar(`New data was added. ` + result, {
            variant: "success",
          });
        })
        .catch((err) => {
          this.props.enqueueSnackbar(`Error while adding data. ` + err, {
            variant: "error",
          });
        });
    }
  }

  render() {
    const {
      display_name,
      continent,
      flag_url,
      area,
      population,
      population_year,
      created_at,
      modified_at,
    } = this.state.country;
    const { classes, type } = this.props;

    return (
      <React.Fragment>
        <Paper className="padding-20" id="edit-form">
          <Typography variant="h3">{type} Country</Typography>
          <form className={classes.root}>
            <FormControl fullWidth>
              <TextField
                id="display_name"
                name="display_name"
                label="Place name"
                value={display_name ? display_name : ""}
                variant="outlined"
                onChange={this.handleFieldChange}
                className="display_name"
                required
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="continent"
                name="continent"
                label="Continent"
                value={continent ? continent : ""}
                variant="outlined"
                required
                onChange={this.handleFieldChange}
                className="continent"
                select
              >
                {continents.map((option) => (
                  <MenuItem key={option.id} value={option.id} name={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="flag_url"
                name="flag_url"
                label="Flag url"
                value={flag_url ? flag_url : ""}
                variant="outlined"
                onChange={this.handleFieldChange}
              />
            </FormControl>
            <div>
              <FormControl>
                <TextField
                  id="area"
                  name="area"
                  label="Area"
                  value={area ? area : ""}
                  variant="outlined"
                  onChange={this.handleFieldChange}
                  type="number"
                  required
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="population"
                  name="population"
                  label="Population"
                  value={population ? population : ""}
                  variant="outlined"
                  onChange={this.handleFieldChange}
                  type="number"
                  required
                />
              </FormControl>
              <FormControl>
                <TextField
                  id="population_year"
                  name="population_year"
                  label="Population year"
                  value={population_year ? population_year : ""}
                  variant="outlined"
                  onChange={this.handleFieldChange}
                  type="number"
                  required
                />
              </FormControl>
            </div>

            <div>
              {created_at && (
                <FormControl>
                  <TextField
                    id="created_at"
                    name="created_at"
                    label="Created at"
                    value={created_at}
                    variant="outlined"
                    disabled
                  />
                </FormControl>
              )}
              {modified_at && (
                <FormControl>
                  <TextField
                    id="modified_at"
                    name="modified_at"
                    label="Modified at"
                    value={modified_at}
                    variant="outlined"
                    disabled
                  />
                </FormControl>
              )}
            </div>

            <hr />
            <CountryLanguagesForm
              availableLanguages={this.state.allAvailableLanguages}
              translations={this.state.country.languages}
              handleTranslationValue={this.handleTranslationValue}
            />

            <FormControl fullWidth>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={() => this.setState({ openDialog: true })}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={this.handleSave.bind(this)}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </Paper>

        <CancelDialog
          title="Cancel work?"
          content="Do you really want to cancel? Your're work will be removed."
          open={this.state.openDialog}
          disagreeButton={() => this.setState({ openDialog: false })}
          backTo="/countries"
        />
      </React.Fragment>
    );
  }
}

export default withSnackbar(withStyles(styles)(CountryForm));
