import React from "react";
import {
  Grid,
  Container,
  Typography,
  CssBaseline,
  Link,
  Box,
} from "@material-ui/core";
import { AuthConsumer } from "../../context/AuthContext";
import LoginForm from "./LoginForm";
import logo from "./../../../images/logo.svg";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        Eggs'n'Bacon Apps
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function LoginPage(props) {
  return (
    <AuthConsumer>
      {({ login }) => (
        <Container maxWidth="xs" id="login-form">
          <CssBaseline />
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <img
              src={logo}
              alt="vojaGO Admin Panel"
              className="login-form-logo"
            />
            <Typography variant="h4">vojaGO</Typography>
            <LoginForm login={login} />
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      )}
    </AuthConsumer>
  );
}
export default LoginPage;
