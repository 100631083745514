import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import LoginPage from "../components/login/LoginPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PlacesTablePage from "../components/places/PlacesTablePage";
import AddPlacePage from "../components/places/forms/AddPlacePage";
import EditPlacePage from "../components/places/forms/EditPlacePage";
import LanguagesTablePage from "../components/languages/LanguagesTablePage";
import UsersTablePage from "../components/users/UsersTablePage";
import FeedbackTablePage from "../components/feedback/FeedbackTablePage";
import SettingsPage from "../components/settings/SettingsPage";
import CountriesTablePage from "../components/countries/CountriesTablePage";
import AddCountryPage from "../components/countries/forms/AddCountryPage";
import EditCountryPage from "../components/countries/forms/EditCountryPage";
import PhotoPage from "../components/places/forms/photos/PhotoPage";

function AppRouter() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <PublicRoute path="/" component={LoginPage} exact={true} />
          <PrivateRoute path="/places/edit/:id/photo" component={PhotoPage} />
          <PrivateRoute path="/places/add" component={AddPlacePage} />
          <PrivateRoute path="/places/edit/:id" component={EditPlacePage} />
          <PrivateRoute path="/places" component={PlacesTablePage} />
          <PrivateRoute path="/countries/add" component={AddCountryPage} />
          <PrivateRoute
            path="/countries/edit/:id"
            component={EditCountryPage}
          />
          <PrivateRoute path="/countries" component={CountriesTablePage} />
          <PrivateRoute path="/languages" component={LanguagesTablePage} />
          <PrivateRoute path="/users" component={UsersTablePage} />
          <PrivateRoute path="/feedback" component={FeedbackTablePage} />
          <PrivateRoute path="/settings" component={SettingsPage} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default AppRouter;
