import React from "react";
import {
  Typography,
  TextField,
  FormControl,
  Paper,
  withStyles,
  Button,
  Grid,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { withSnackbar } from "notistack";
import { addPhoto, editPhoto } from "../../../../../Actions";
import { withRouter } from "react-router-dom";
import { green } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
});

class PhotoForm extends React.Component {
  state = {
    type: this.props.photo ? "Edit" : "Add",
    photo: this.props.photo ?? {
      id_place: this.props.placeId,
    },
    files: [],
    progress: false,
  };

  constructor(props) {
    super(props);

    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  handleFieldChange(event) {
    this.setState({
      photo: {
        ...this.state.photo,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleChange(files) {
    this.setState({
      files: files,
    });
  }

  handleSave() {
    const photoFile = this.state.files[0];
    const metaData = this.state.photo;

    var request;
    if (this.state.type === "Edit") {
      request = editPhoto(photoFile, metaData);
    } else {
      request = addPhoto(photoFile, metaData);
    }

    this.setState({
      progress: true,
    });

    request
      .then(() => {
        this.setState({
          progress: false,
        });
        this.props.enqueueSnackbar(`Photo added/modified sucesfully.`, {
          variant: "success",
        });
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({
          progress: false,
        });
        this.props.enqueueSnackbar(`Error while adding data. ` + err, {
          variant: "error",
        });
      });
  }

  render() {
    const { type, progress } = this.state;
    const {
      author,
      source_url,
      license,
      license_url,
      thumb_url,
      photo_url,
      zoomed_url,
    } = this.state.photo;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Paper className="padding-20" id="edit-form">
          <Typography variant="h3">{type} Photo</Typography>
          <form className={classes.root}>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    id="author"
                    name="author"
                    label="Author"
                    value={author ? author : ""}
                    variant="outlined"
                    onChange={this.handleFieldChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <TextField
                    id="source_url"
                    name="source_url"
                    label="Source URL"
                    value={source_url ? source_url : ""}
                    variant="outlined"
                    type="text"
                    onChange={this.handleFieldChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    id="license"
                    name="license"
                    label="License"
                    value={license ? license : ""}
                    variant="outlined"
                    onChange={this.handleFieldChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <TextField
                    id="license_url"
                    name="license_url"
                    label="License URL"
                    value={license_url ? license_url : ""}
                    variant="outlined"
                    type="text"
                    onChange={this.handleFieldChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {type === "Edit" && (
              <Grid container item xs={12} spacing={1}>
                {thumb_url && (
                  <Grid item xs={4}>
                    <Typography variant="h6">{type} Thumb:</Typography>
                    <img
                      className={classes.img}
                      alt="complex"
                      src={thumb_url}
                    />
                  </Grid>
                )}
                {photo_url && (
                  <Grid item xs={4}>
                    <Typography variant="h6">{type} Regular:</Typography>
                    <img
                      className={classes.img}
                      alt="complex"
                      src={photo_url}
                    />
                  </Grid>
                )}
                {zoomed_url && (
                  <Grid item xs={4}>
                    <Typography variant="h6">{type} Zoomed:</Typography>
                    <img
                      className={classes.img}
                      alt="complex"
                      src={zoomed_url}
                    />
                  </Grid>
                )}
              </Grid>
            )}

            <DropzoneArea
              acceptedFiles={["image/jpeg", "image/png"]}
              filesLimit={1}
              onChange={this.handleChange.bind(this)}
            />

            {progress && <LinearProgress />}

            <FormControl fullWidth>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.wrapper}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={progress}
                      size="large"
                      onClick={this.handleSave.bind(this)}
                    >
                      Save
                    </Button>
                    {progress && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withRouter(withSnackbar(withStyles(styles)(PhotoForm)));
