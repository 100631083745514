import axios from "axios";
import { serialize } from "object-to-formdata";

const API_URL = "https://api.voiago.eastcodes.eu/panel";
const API_SALT = "RGFpbHlUcmF2ZWxTYWx0";

axios.defaults.headers.common["AuthSalt"] = API_SALT;

export const auth = (login, password) =>
  axios.post(`${API_URL}/admins/login`, {
    login: login,
    password: password,
  });

export const setAuthToken = (token) => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common["Authorization"] = `Basic ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export function getCountries(skip, take, orderBy, orderDirection, searchName) {
  return axios.post(`${API_URL}/countries/query`, {
    skip: skip,
    take: take,
    order: orderBy,
    direction: orderDirection,
    name: searchName,
  });
}

export function getCountryById(id) {
  return axios.get(`${API_URL}/countries/${id}`);
}

export function getAllCountries() {
  return axios.get(`${API_URL}/countries`);
}

export const addCountry = (country) =>
  axios.post(`${API_URL}/countries`, country);

export const editCountry = (id, country) =>
  axios.put(`${API_URL}/countries/${id}`, country);

export function getPlaces(skip, take, orderBy, orderDirection, searchName) {
  return axios.post(`${API_URL}/places/query`, {
    skip: skip,
    take: take,
    order: orderBy,
    direction: orderDirection,
    filters: searchName,
  });
}

export function getPlaceByID(id) {
  return axios.get(`${API_URL}/places/${id}`);
}

export const getLanguages = () => axios.get(`${API_URL}/languages`);

export const addPlace = (place) => axios.post(`${API_URL}/places`, place);

export const editPlace = (id, place) =>
  axios.put(`${API_URL}/places/${id}`, place);

export const deletePlace = (placeId) =>
  axios.delete(`${API_URL}/places/${placeId}`);

export const getPlacePhotos = (placeId) =>
  axios.get(`${API_URL}/photos/place/${placeId}`);

export const addPhoto = (photoFile, metaData) => {
  const formData = serialize(metaData);
  formData.append("photo_file", photoFile);
  return axios.post(`${API_URL}/photos/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const editPhoto = (photoFile, metaData) => {
  const formData = serialize(metaData);
  if (photoFile !== undefined) {
    formData.append("photo_file", photoFile);
  }
  return axios.post(`${API_URL}/photos/edit`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deletePhoto = (photoId) =>
  axios.delete(`${API_URL}/photos/${photoId}`);

export const changePhotoCover = (placeId, photoId) =>
  axios.put(`${API_URL}/photos/place/${placeId}/cover/${photoId}`);
