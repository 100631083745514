import React from "react";
import { withSnackbar } from "notistack";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import LockIcon from "@material-ui/icons/Lock";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loading: false,
      success: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginChange = this.handleLoginChange.bind(this);
    this.handlePassChange = this.handlePassChange.bind(this);
  }

  handleLoginChange(e) {
    this.setState({
      username: e.target.value,
    });
  }
  handlePassChange(e) {
    this.setState({
      password: e.target.value,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.login(this.state.username.trim(), this.state.password.trim());
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <TextField
            id="username"
            label="Login"
            name="username"
            variant="outlined"
            autoFocus
            required
            onChange={this.handleLoginChange}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            id="password"
            label="Password"
            name="password"
            variant="outlined"
            type="password"
            required
            onChange={this.handlePassChange}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <Button
            type="submit"
            size="large"
            fullWidth
            disabled={this.state.loading}
          >
            <LockIcon />
            <span>Sign In</span>
          </Button>
        </FormControl>
        <div className="align-center">
          {this.state.loading && <CircularProgress />}
        </div>
      </form>
    );
  }
}
export default withSnackbar(LoginForm);
