import React from "react";
import { AppBar, Toolbar, Typography, Button, Grid } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { AuthContext } from "../../context/AuthContext";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.context.logout();
  }

  render() {
    return (
      <AppBar position="sticky" >
        <Toolbar>
          <Grid item xs={10}>
            <Typography variant="h5" color="inherit">
              vojaGO Panel
            </Typography>
          </Grid>
          <Grid item xs={2} className="align-right">
            <Button
              size="small"
              startIcon={<LockOpenIcon />}
              onClick={this.onLogoutClick}
              color="secondary"
              variant="contained"
            >
              Logout
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.contextType = AuthContext;

export default Header;
