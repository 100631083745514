import React from "react";
import PhotoForm from "./PhotoForm";

class PhotoPage extends React.Component {
  render() {
    const { id } = this.props.match.params;
    const photo = this.props.location.state?.photo;
    return <PhotoForm placeId={id} photo={photo} />;
  }
}

export default PhotoPage;
