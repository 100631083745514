import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link, Typography } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Filter1Icon from "@material-ui/icons/Filter1";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
});

const PhotosTableRow = (props) => {
  const { url } = useRouteMatch();
  const { classes } = props;
  const {
    id,
    author,
    cover,
    license,
    license_url,
    thumb_url,
    source_url,
  } = props.photo;

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className="image-container">
        <Grid item xs={12} sm={2}>
          <ButtonBase>
            {/* TODO: Maybe add some lightbox here? */}
            <img className={classes.img} alt="complex" src={thumb_url} />
          </ButtonBase>
        </Grid>
        <Grid item xs={11} sm={9}>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="body2">
                License:{" "}
                <Link href={license_url ? license_url : "#"} target="_blank">
                  {license ? license : "No license"}
                </Link>
              </Typography>
              {author && (
                <Typography variant="body2" gutterBottom>
                  Author: {author}
                </Typography>
              )}
              {source_url && (
                <Typography variant="body2" gutterBottom>
                  Source:{" "}
                  <Link href={source_url} target="_blank">
                    <LinkIcon />
                  </Link>
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary">
                Cover: {cover ? <CheckCircleIcon /> : <HighlightOffIcon />}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1}>
          <Link
            component={RouterLink}
            to={{
              pathname: `${url}/photo`,
              state: { photo: props.photo },
            }}
          >
            <IconButton title="Edit">
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton
            title="Delete"
            onClick={() => props.handleDeletePhoto(id)}
          >
            <DeleteForeverIcon />
          </IconButton>
          {!cover && (
            <IconButton
              title="Change cover"
              onClick={() => props.handleChangePhotoCover(id)}
            >
              <Filter1Icon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(PhotosTableRow);
