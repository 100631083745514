import React from "react";
import { getPlaces } from "../../../Actions";
import MaterialTable from "material-table";
import { withSnackbar } from "notistack";
import { deletePlace } from "./../../../Actions";

class PlacesTablePage extends React.Component {
  publishDate = {
    title: "Publish date",
    field: "publish_date",
    type: "date",
    sorting: true,
    defaultSort: "desc",
  };

  constructor(props) {
    super(props);

    this.state = {
      pageSize: 30,
    };
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  handleChangeRowsPerPage(pageSize) {
    this.setState({ pageSize: pageSize });
  }

  loadData(query) {
    if (typeof query.orderBy === "undefined") {
      query.orderBy = this.publishDate;
      query.orderDirection = this.publishDate.defaultSort;
    }
    return getPlaces(
      query.page * query.pageSize,
      query.pageSize,
      query.orderBy.field,
      query.orderDirection,
      query.search
    )
      .then((result) => {
        return {
          data: result.data.entities,
          page: query.page,
          totalCount: result.data.total,
        };
      })
      .catch((err) =>
        this.props.enqueueSnackbar(`Error while loading data. `, {
          variant: "error",
        })
      );
  }

  render() {
    return (
      <MaterialTable
        title="Places"
        columns={[
          {
            title: "Publish date",
            field: "publish_date",
            type: "date",
            sorting: true,
            defaultSort: "desc",
          },
          {
            title: "Name",
            field: "display_name",
            sorting: true,
          },
          {
            title: "Country",
            field: "country_name",
            sorting: true,
          },
          {
            title: "Continent",
            field: "country_continent",
            sorting: false,
            lookup: {
              AFRICA: "Africa",
              ASIA: "Asia",
              AUSTRALIA: "Australia",
              EUROPE: "Europe",
              NORTH_AMERICA: "North America",
              SOUTH_AMERICA: "South America",
            },
          },
          {
            title: "Status",
            field: "status",
            sorting: false,
          },
          {
            title: "Category",
            field: "category",
            sorting: false,
            lookup: {
              BEACHES: "Beaches",
              BRIDGES: "Bridges",
              ISLANDS: "Islands",
              MUSEUMS: "Museums",
              MONUMENTS_AND_STATUES: "Monuments & Statues",
              MOUNTAINS_AND_VOLCANO: "Mountains & Volcano",
              PARKS_AND_NATURE: "Parks & Nature",
              SIGHTS_AND_LANDMARKS: "Sights & Landmarks",
              SACRED_AND_RELIGIOUS: "Sacred & Religious",
            },
          },
        ]}
        data={(query) => this.loadData(query)}
        options={{
          filtering: false,
          actionsColumnIndex: -1,
          pageSize: this.state.pageSize,
          pageSizeOptions: [30, 40, 50],
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Add Place",
            isFreeAction: true,
            onClick: (event) => {
              this.props.history.push("/places/add");
            },
          },
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              this.props.history.push("/places/edit/" + rowData.id);
            },
          },
        ]}
        editable={{
          onRowDelete: (place) =>
            deletePlace(place.id)
              .then(() => {
                this.props.enqueueSnackbar(`The data has been deleted.`, {
                  variant: "success",
                });
              })
              .catch(() => {
                this.props.enqueueSnackbar(`Error while deleting data.`, {
                  variant: "error",
                });
              }),
        }}
      />
    );
  }
}
export default withSnackbar(PlacesTablePage);
