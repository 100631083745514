import React from "react";
import CountryForm from "./CountryForm";

class EditCountryPage extends React.Component {  
  render() {
    return (
      <CountryForm type="Edit" countryID={this.props.match.params.id} />
    );
  }
}

export default EditCountryPage;
