import React from "react";
import PlaceForm from "./PlaceForm";

class EditPlacePage extends React.Component {  
  render() {
    return (
      <PlaceForm type="Edit" placeID={this.props.match.params.id} />
    );
  }
}

export default EditPlacePage;
