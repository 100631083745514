import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "../context/AuthContext";

function PublicRoute({ component: Component, ...rest }) {
  return (
    <AuthConsumer>
      {({ isAuth }) => (
        <Route
          render={(props) =>
            isAuth ? <Redirect to="/places" /> : <Component {...props} />
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  );
}

export default PublicRoute;
