import React from "react";
import {
  deletePhoto,
  getPlacePhotos,
  changePhotoCover,
} from "../../../../../Actions";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import PhotosTableRow from "./PhotosTableRow";
import { withStyles } from "@material-ui/core/styles";
import CancelDialog from "../../../dialogs/CancelDialog";

const styles = (theme) => ({
  // TODO: Apply some styles?
});

class PhotosTable extends React.Component {
  state = {
    photos: [],
    openDialog: false,
    photoToDelete: 0,
  };

  componentDidMount() {
    getPlacePhotos(this.props.placeId)
      .then((result) =>
        this.setState({
          photos: result.data,
        })
      )
      .catch(() =>
        this.props.enqueueSnackbar(`Error while fetching place photos.`, {
          variant: "error",
        })
      );
  }

  handleDeletePhoto = (photoId) => {
    this.setState({
      photoToDelete: photoId,
      openDialog: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleDeletePhotoConfirmation = () => {
    deletePhoto(this.state.photoToDelete)
      .then(() => {
        let filteredPhotos = this.state.photos.filter(
          (item) => item.id !== this.state.photoToDelete
        );
        this.setState({
          photos: filteredPhotos,
          openDialog: false,
          photoToDelete: 0,
        });
        this.props.enqueueSnackbar(`Operation successful.`, {
          variant: "success",
        });
      })
      .catch(() =>
        this.props.enqueueSnackbar(`Error while deleting photo.`, {
          variant: "error",
        })
      );
  };

  handleChangePhotoCover = (photoId) => {
    changePhotoCover(this.props.placeId, photoId)
      .then(() => {
        let updatedPhotos = this.state.photos;
        updatedPhotos.forEach((item) => (item.cover = item.id === photoId));
        this.setState({
          photos: updatedPhotos,
        });
        this.props.enqueueSnackbar(`Operation successful.`, {
          variant: "success",
        });
      })
      .catch(() =>
        this.props.enqueueSnackbar(`Error while changing photo cover.`, {
          variant: "error",
        })
      );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.photos.map((photo) => (
          <PhotosTableRow
            key={photo.id}
            photo={photo}
            handleDeletePhoto={this.handleDeletePhoto.bind(this)}
            handleChangePhotoCover={this.handleChangePhotoCover.bind(this)}
          />
        ))}
        <CancelDialog
          title="Delete photo?"
          content="Do you really want to delete this photo?"
          open={this.state.openDialog}
          agreeButton={this.handleDeletePhotoConfirmation.bind(this)}
          disagreeButton={this.handleDialogClose.bind(this)}
        />
      </React.Fragment>
    );
  }
}

PhotosTable.propTypes = {
  placeId: PropTypes.number.isRequired,
};

export default withSnackbar(withStyles(styles)(PhotosTable));
