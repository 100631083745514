import React from "react";
import { SnackbarProvider } from 'notistack';
import AppRouter from "./web/routers/AppRouter";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      light:'#ffac5f',
      main: '#FF8615',
      dark: '#FD6100',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#026BFC',
      main: '#0360FA',
      dark: '#0848F7',
      contrastText: '#ffffff'
    }
  }
})

function Main() {
  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  };
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} anchorOrigin={anchorOrigin}>
        <AppRouter />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
export default Main;
